.App {
  text-align: center;
}

img {
  height:200px;
  padding-top: 40px;
}

.box-left {
  width: 200px;
  flex: 1;
  padding-right: 40px;
}

.box-right {
  width: 300px;
  flex: 1;
  text-align: left;
}

.bar {
  height:10px;
  transition: 0.5s;
}

p {
  margin:0
}

.wrapper {
  height:10px;
  border: 1px solid black;
}

h3 {
  text-align: center;
}

.pokemon {
  width: 400px;
  margin:auto;
  display:flex;
  padding-bottom: 50px;
}